<template>
  <div class="mx-auto max-w-screen-xl">
    <h2 v-if="title" class="mb-2 text-center text-3xl text-gray-900 md:mb-8 md:text-4xl lg:text-5xl">
      {{ title }}
    </h2>
    <div class="mx-auto w-full max-w-screen-md">
      <div
        data-accordion="collapse"
        data-active-classes="text-gray-900"
        data-inactive-classes="text-gray-500"
        class="flex flex-col gap-1">
        <div
          v-for="item in faqItems"
          :key="item.id"
          :class="{
            'cursor-pointer border-b border-gray-200 transition ease-in-out last:border-b-0': size === 'small',
            'cursor-pointer rounded-2xl border border-white bg-white px-3 transition ease-in-out hover:border-gray-900 md:px-6':
              size === 'normal',
          }">
          <h2 :id="'accordion-flush-heading-' + item.id">
            <button
              type="button"
              class="flex w-full items-center justify-between gap-x-2 py-4 text-left font-medium text-gray-900"
              :class="{ 'bg-white py-6': size === 'normal' }"
              :data-accordion-target="'#accordion-collapse-body-' + item.id"
              aria-expanded="true"
              :aria-controls="'accordion-collapse-body-' + item.id">
              <span
                class="text-base font-normal leading-snug text-gray-900 md:text-lg"
                :class="{ 'leading-normal md:text-2xl': size === 'normal' }">
                {{ item.attributes.question }}
              </span>

              <span>
                <SvgoChevronRight
                  class="h-4 w-4 rotate-90 md:h-5 md:w-5"
                  :class="{ 'md:h-6 md:w-6': size === 'normal' }" />
              </span>
            </button>
          </h2>
          <div :id="'accordion-collapse-body-' + item.id" :aria-labelledby="'accordion-collapse-heading-' + item.id">
            <div class="pb-4" :class="{ 'pb-5': size === 'normal' }">
              <div class="faq--content mb-2 text-sm text-gray-900 md:text-base" v-html="item.attributes.answer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { initAccordions } from "flowbite";
import { onMounted } from "vue";
import type { FaqItem } from "~/types/strapi/api/faq-item/content-types/faq-item/faq-item";

const props = withDefaults(
  defineProps<{
    faqItems: FaqItem[];
    title?: string;
    size?: "normal" | "small";
  }>(),
  {
    faqItems: () => [],
    size: "normal",
  },
);

onMounted(() => {
  initAccordions();
});

useFaqPage(props.faqItems);
</script>

<style scoped>
.faq--content :deep(a) {
  text-decoration: underline;
}
.faq--content :deep(a):hover {
  @apply text-black;
  @apply transition;
}
.faq--content :deep(p) {
  @apply mb-3;
}
.faq--content :deep(ul) {
  @apply mb-3 list-outside list-disc pl-5;
}
.faq--content :deep(ul) > li > ul {
  @apply pl-5;
}

button[aria-expanded="true"] svg {
  @apply -rotate-90;
}
</style>
